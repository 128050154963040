import { initTracking } from "@abley/support/analytics-react";
import { analyticsConfig } from "./lib/config";
void initTracking(analyticsConfig);

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter,RouterProvider } from "react-router-dom";

import { routesConfig } from "./routes";
import store from "./store";

// Create browser history to use in the Redux store
const rootBaseName = import.meta.env.BASE_URL;

const router = createBrowserRouter(
	routesConfig,
	{ basename: rootBaseName }
);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>,
);
