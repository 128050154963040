import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from ".";
import { fetchSurveyDetails, resetUserData } from "./surveySlice";

export interface IOptionalQuestionState {
	answers: IOptionAnswer[];
}

const initialState: IOptionalQuestionState = {
	answers: [],
};

export enum OptionAnswerProperties {
	OptionalQuestionID = "OptionalQuestionID",
	MultipleChoiceOption = "MultipleChoiceOption",
	FreeTextResponse = "FreeTextResponse",
}

export interface IOptionAnswer {
	[OptionAnswerProperties.OptionalQuestionID]: string;
	[OptionAnswerProperties.MultipleChoiceOption]: string;
	[OptionAnswerProperties.FreeTextResponse]: string;
}

const optionalQuestionSlice = createSlice({
	name: "optionalQuestionSlice",
	initialState,
	reducers: {
		writeOptionalAnswer: (state, action: PayloadAction<{ questionID: string; fieldToUpdate: string; answerToFieldQuestion: string }>) => {
			const { questionID, fieldToUpdate, answerToFieldQuestion } = action.payload;
			state.answers = state.answers.map((answer) =>
				answer.OptionalQuestionID === questionID
					? {
							...answer,
							[fieldToUpdate]: answerToFieldQuestion,
						}
					: answer
			);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchSurveyDetails.fulfilled, (state, action) => {
				if (!action.payload || !action.payload.optionalQuestions.length) return;
				state.answers = action.payload.optionalQuestions.map(
					(q) =>
						({
							OptionalQuestionID: q.UniqueID,
							MultipleChoiceOption: "",
							FreeTextResponse: "",
						}) as IOptionAnswer
				);
			})
			.addCase(resetUserData, () => initialState);
	},
});

export const { writeOptionalAnswer } = optionalQuestionSlice.actions;

export const selectOptionalQuestionAnswers = (state: ApplicationState): IOptionAnswer[] => state.optionalQuestionState.answers;

export const reducer = optionalQuestionSlice.reducer;
