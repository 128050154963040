/**
 * This module is designed to supplement the Redux store by providing a hosting environment for
 * application wide shared non-serializable and independently mutable runtime data.
 */
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";

interface StoreCache {
	// navSlice data
	ga4?: GA4ReactResolveInterface;
}

const store: StoreCache = {
	ga4: undefined,
};

export default store;
