import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from ".";
import storeCache from "../models/storeCache";

import GA4React from "ga-4-react";
import config from "../lib/config";

const SliceName = "nav";

// -----------------
// STATE - This defines the type of data maintained in this Redux slice.
export interface NavState extends NavPageState {
	gaInitialized: boolean;
}

const initialState: NavState = {
	gaInitialized: false,
	pageName: "",
	isHidden: false,
};

// -----------------
// Thunk action creators
const initializeGA = createAsyncThunk<
	boolean,
	void,
	{
		state: ApplicationState;
	}
>(`${SliceName}/initializeGA`, async (_, { getState }) => {
	const navState = getState().navState;
	if (!navState.gaInitialized)
		if (!GA4React.isInitialized()) {
			const ga4react = new GA4React(config.gaTrackingId);
			storeCache.ga4 = await ga4react.initialize();
		}
	return GA4React.isInitialized();
});

// ----------------
// Redux slice
interface NavPageState {
	pageName: string;
	isHidden: boolean;
}

const slice = createSlice({
	name: SliceName,
	initialState,
	reducers: {
		setNavPage: {
			reducer: (state, { payload }: PayloadAction<NavPageState>) => {
				if (state.gaInitialized) {
					storeCache.ga4?.pageview(payload.pageName);
				}

				state.pageName = payload.pageName;
				state.isHidden = payload.isHidden;
			},
			prepare: (pageName: string, isHidden?: boolean) => {
				isHidden = isHidden ?? false;

				return {
					payload: {
						pageName,
						isHidden,
					},
				};
			},
		},
	},
	extraReducers: (builder) => {
		builder.addCase(initializeGA.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
			state.gaInitialized = payload;
		});
	},
});

// ----------------
// exports
export const selector = (state: ApplicationState): NavState => state.navState;
export const actions = {
	...slice.actions,
	initializeGA,
};
export const reducer = slice.reducer;
