import clsx from "clsx";
import React, { ReactElement } from "react";
import GenericTooltip from "./GenericTooltip";

interface IProps {
	title: React.ReactNode;
	bigTitle?: boolean;
	description?: React.ReactNode;
	showTooltip?: boolean;
	tooltip?: string;
}

const PageHeadings = ({ title, bigTitle = false, description, showTooltip, tooltip }: IProps): ReactElement => (
	<>
		<div className="d-none d-md-block py-3" />
		<div className="d-flex align-items-baseline py-1">
			<span className={clsx("me-2 fw-bold line-height-reduced", bigTitle ? "display-6" : "fs-2")}>{title}</span>
			{showTooltip && <GenericTooltip content={tooltip ?? ""} />}
		</div>
		{description && (
			<>
				<div className="d-none d-md-block fs-5">{description}</div>
				<div className="d d-md-none fs-6-plus">{description}</div>
			</>
		)}
	</>
);

export default PageHeadings;
