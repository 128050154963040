import clsx from "clsx";
import React, { ReactElement } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

interface IProps {
	rootClass?: string;
	rowClass?: string;
	colClass?: string;
	children: React.ReactNode;
}

const SurveyPageContainer = ({ rootClass, rowClass, colClass, children }: IProps): ReactElement => {
	return (
		<Container fluid className={clsx("flex-grow-1", rootClass)}>
			<Row className={clsx("d-flex justify-content-center", rowClass)}>
				<Col xs={12} md={10} lg={8} xxl={6} className={clsx("p-4-plus", colClass)}>
					{children}
				</Col>
			</Row>
		</Container>
	);
};

export default SurveyPageContainer;
