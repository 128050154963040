import { ReactElement } from "react";
import VerticalSpace from "./VerticalSpace";

/**
 * Creates vertical space on large screen
 */
const EmptySpaceLgScreen = (): ReactElement => (
	<div className="d-none d-lg-block">
		<VerticalSpace />
		<VerticalSpace />
	</div>
);

export default EmptySpaceLgScreen;
