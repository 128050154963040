import axios from "axios";
import { captureException } from "@abley/support/analytics-react";

import { logger } from "./logApi";

const log = logger();

export const appAxios = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
});

export interface NoneAxiosError {
	name: string;
	message?: string;
	stack: string;
}

export const axiosErrorHelper = (error: unknown): void => {
	captureException(error as Error);
	if (axios.isAxiosError(error)) {
		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
			log.info(error.response.data);
			log.info(error.response.status);
			log.info(error.response.headers);
		} else if (error.request) {
			// The request was made but no response was received
			// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
			// http.ClientRequest in node.js
			log.info(error.request);
		} else {
			// Something happened in setting up the request that triggered an Error
			log.info("Error", error.message);
		}
		log.info(error.config);
	} else {
		log.info(error);
	}
};
