export const NAV_LINKS = {
	Company: "/",
	NoOpenSurveysPage: "/no-open-surveys",
	Home: "/start",
	QuestionEmail: "/email",
	QuestionMainWorkplace: "/main-workplace",
	QuestionAddress: "/address",
	QuestionCommute: "/commute",
	QuestionOptional: "/optional-question",
	QuestionHaveYouSay: "/have-you-say",
	ThankYou: "/thank-you",
	Exit: "/exit",
	NotFound: "*",
};

/**
 * Align with the class names defined in App.scss
 */
export const ICON_BUTTON_CONFIG = {
	size: 50,
	rootClass: "icon-button",
	backgroundClass: "icon-button--background",
	arrowClass: "icon-button--arrow",
};
