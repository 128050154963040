import { createAsyncThunk, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Joi from "joi";
import { tlds } from "@hapi/tlds";
import { ApplicationState } from ".";
import { FetchStatus } from "./../constants/surveyConstants";
import { appAxios } from "../lib/appAxios";
import { resetUserData } from "./surveySlice";

export interface EmailSliceState {
	emailPageProps: EmailPageProps;
	fetchStatus: FetchStatus;
}

export interface EmailPageProps {
	currentUserEmail: string;
	hasResponse: boolean | undefined;
	emailAddressValid: boolean | undefined;
}

export const initialEmailPageProps = {
	currentUserEmail: "",
	hasResponse: undefined,
	emailAddressValid: undefined,
};

const initialState: EmailSliceState = { emailPageProps: { ...initialEmailPageProps }, fetchStatus: FetchStatus.IDLE };

interface IEmailHasResponse {
	email: string;
	hasResponses: boolean;
}

export const emailHasResponse = createAsyncThunk<boolean, string, { state: ApplicationState }>("survey/emailHasResponse", async (email, { getState }) => {
	const { csrfToken, surveyID } = getState().surveyState;
	const response = await appAxios.get(`/surveys/${surveyID}/response`, { headers: { "x-csrf-token": csrfToken, email }, withCredentials: true });
	const emailHasResponseData = response.data as IEmailHasResponse;
	return emailHasResponseData.hasResponses;
});

const emailSlice = createSlice({
	name: "emailSlice",
	initialState,
	reducers: {
		setUserEmail: (state, { payload }: PayloadAction<EmailPageProps>) => {
			state.emailPageProps.currentUserEmail = payload.currentUserEmail;
		},
		resetHasResponseState: (state) => {
			state.emailPageProps.hasResponse = undefined;
		},
		resetEmailAddressValidity: (state) => {
			state.emailPageProps.emailAddressValid = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(emailHasResponse.fulfilled, (state, { payload }) => {
				state.emailPageProps.hasResponse = payload;
				state.emailPageProps.emailAddressValid = true;
			})
			.addCase(emailHasResponse.rejected, (state) => {
				// put validation code here as a walk-around to avoid the sync problem of onClick event
				let emailValid = true;
				try {
					Joi.assert(
						state.emailPageProps.currentUserEmail,
						Joi.string()
							.email({ tlds: { allow: tlds } })
							.required()
					);
				} catch {
					emailValid = false;
				}
				if (!emailValid) {
					state.emailPageProps.emailAddressValid = emailValid;
				} else {
					state.fetchStatus = FetchStatus.FAILURE;
				}
			})
			.addCase(resetUserData, () => initialState);
	},
});

export const selectUserEmailsFetchStatus = (state: ApplicationState): FetchStatus => state.emailSliceState.fetchStatus;
export const selectEmailPageProps = (state: ApplicationState): EmailPageProps => state.emailSliceState.emailPageProps;
export const selectEmail = createSelector(selectEmailPageProps, (state): string => state.currentUserEmail);
export const actions = {
	...emailSlice.actions,
	emailHasResponse,
};

export const { resetHasResponseState, resetEmailAddressValidity } = emailSlice.actions;

export const reducer = emailSlice.reducer;
