import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from ".";

export interface IState {
	mainWorkplaceId: string;
}

const initialState: IState = {
	mainWorkplaceId: "",
};

const mainWorkplaceSlice = createSlice({
	name: "mainWorkplaceSlice",
	initialState,
	reducers: {
		updateMainWorkplace: (state, action: PayloadAction<string>) => {
			state.mainWorkplaceId = action.payload;
		},
	},
});

export const selectMainWorkplaceId = (state: ApplicationState) => state.mainWorkplaceState.mainWorkplaceId;

export const { updateMainWorkplace } = mainWorkplaceSlice.actions;

export const reducer = mainWorkplaceSlice.reducer;
