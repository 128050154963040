import { default as loglevel, Logger, LogLevelDesc } from "loglevel";

const logContext = import.meta.env.VITE_TITLE as string;

// Provide a fallback value if none found
const logLevel = (import.meta.env.VITE_LOGLEVEL as LogLevelDesc) || "debug";

let log: Logger;

const initializeLogger = (): Logger => {
	log = loglevel.getLogger(logContext || "app");
	log.setLevel(logLevel);
	return log;
};

/**
 * A logger using "loglevel" with pre-configured log level
 */
export const logger = (): Logger => (log ? log : initializeLogger());
