import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, ApplicationState } from "./";
import { selectCommuteStateInArray } from "./commuteSlice";
import { selectEmail } from "./emailSlice";
import { ISurveyStateForSubmit } from "./helpers/interfacesAndEnums";
import { selectHomeAddressFields, selectLowCertaintyAddress, selectAddressGeocode } from "./homeAddressSlice";
import { selectMainWorkplaceId } from "./mainWorkplaceSlice";
import { selectOptionalQuestionAnswers } from "./optionalQuestionSlice";
import { selectSurveyID } from "./surveySlice";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<ApplicationState> = useSelector;

/**
 * Bring together user input from all the survey states
 * @return the data to submit
 */
export const useSurveyStateForSubmit = (): ISurveyStateForSubmit => {
	const surveyID = useAppSelector(selectSurveyID);
	if (!surveyID) {
		throw new Error("useSurveyStateForSubmit: No surveyID found");
	}
	const commuteState = useAppSelector(selectCommuteStateInArray);
	const email = useAppSelector(selectEmail);
	const geocode = useAppSelector(selectAddressGeocode);
	const latitude = geocode.latitude;
	const longitude = geocode.longitude;
	const homeAddressFiledProps = useAppSelector(selectHomeAddressFields);
	const noAddressEntered = homeAddressFiledProps.noAddressEntered;
	const lowCertaintyAddress = useAppSelector(selectLowCertaintyAddress);
	const optionalQuestionAnswers = useAppSelector(selectOptionalQuestionAnswers);
	const browserDate = new Date().toDateString();
	const mainWorkplaceId = useAppSelector(selectMainWorkplaceId);

	return {
		SurveyID: surveyID,
		Email: email,
		Latitude: latitude,
		Longitude: longitude,
		NoAddressEntered: noAddressEntered,
		LowCertaintyAddress: lowCertaintyAddress,
		Trips: commuteState,
		Answers: optionalQuestionAnswers,
		ClaimedCompletionDate: browserDate,
		MainWorkplaceID: mainWorkplaceId,
	};
};
