import { type AnalyticsReact } from "@abley/support/analytics-react";
import brandIconDesktop from "../assets/CarbonWise Logo RGB_REV.svg";

// settings for all environments
const title = import.meta.env.VITE_TITLE as string;

// Abley Google Analytics / Development
const gaTrackingId = import.meta.env.VITE_GA_TRACKINGID as string;

export default {
	gaTrackingId,
	title,
	aboutCarbonwiseLink: "https://abley.com/en-us/carbonwise/",
	termsOfUseLink: "https://abley.com/en-us/terms-of-use/",
	privacyStatementLink: "https://abley.com/en-us/privacy-statement/",
	tipsBlogLink: "https://abley.com/en-us/4-ways-to-reduce-your-commuting-emissions/",
	faqLink: "https://abley.com/en-us/carbonwise-faq/",
	brandIcon: {
		src: {
			desktop: brandIconDesktop,
		},
		alt: "Logo",
	},
};

export const analyticsConfig: AnalyticsReact = {
	enabled: import.meta.env.VITE_MONITORING === "true",
	config: {
		dsn: import.meta.env.VITE_SENTRY_DSN,
		environment: import.meta.env.VITE_HOSTENV,
		captureLogs: true,
		initialScope: { tags: { ["carbonwise.instance"]: location.hostname } },
	},
	logger: console,
} as const;
