import type { AsyncThunkPayloadCreator, PayloadAction } from "@reduxjs/toolkit";
import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { debounce } from "ts-debounce";
import axios from "axios";

import type { ApplicationState, ThunkApi } from ".";
import { appAxios, axiosErrorHelper } from "../lib/appAxios";
import { ERROR_MESSAGE_DEFAULT, ERROR_MESSAGE_SESSION, FetchStatus } from "./../constants/surveyConstants";
import { resetUserData } from "./surveySlice";

enum AddressResultType {
	locality = "locality",
	street = "street",
	houseNumber = "houseNumber",
	administrativeArea = "administrativeArea",
	addressBlock = "addressBlock",
	intersection = "intersection",
	postalCodePoint = "postalCodePoint",
}

/**
 * Results of type locality have an additional field localityType that further defines results of types.
 * See details at https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/result-types-address.html
 */
enum LocalityType {
	city = "city",
	postalCode = "postalCode",
	district = "district",
	subdistrict = "subdistrict",
}

export type AutocompleteAddress = {
	address: {
		label: string;
	};
};

type AutocompleteResponse = {
	items: AutocompleteAddress[];
};

interface HereGeocodeResult {
	localityType?: LocalityType;
	resultType: AddressResultType;
	position: {
		lat: number;
		lng: number;
	};
}

interface GeocodeResponse {
	items: HereGeocodeResult[];
}

interface HomeAddressFields {
	homeAddress: string;
	noAddressEntered: boolean;
	suggestedAddresses: AutocompleteAddress[];
}

export const initialHomeAddressFields = {
	homeAddress: "",
	noAddressEntered: true,
	suggestedAddresses: [],
};

interface Geocode {
	latitude: null | number;
	longitude: null | number;
}

export interface HomeAddressSliceState {
	homeAddressFields: HomeAddressFields;
	noStreetAddressChecked: boolean;
	lowCertaintyAddress: boolean;
	homeAddressGeocode: Geocode;
	fetchStatus: FetchStatus;
	fetchErrorMessage: string;
}

const initialState: HomeAddressSliceState = {
	homeAddressFields: { ...initialHomeAddressFields },
	noStreetAddressChecked: false,
	lowCertaintyAddress: false,
	homeAddressGeocode: { latitude: null, longitude: null },
	fetchStatus: FetchStatus.IDLE,
	fetchErrorMessage: "",
};

const API_CALL_INTERVAL_MILLISECOND = 300;

const FETCH_AUTOCOMPLETE_ACTIONS = {
	pending: `autocomplete/${FetchStatus.PENDING}`,
	success: `autocomplete/${FetchStatus.SUCCESS}`,
	failure: `autocomplete/${FetchStatus.FAILURE}`,
};
const fetchAutocompleteSuccess = createAction(FETCH_AUTOCOMPLETE_ACTIONS.success, (items: AutocompleteAddress[]) => ({
	payload: items,
}));
const fetchAutocompleteFailure = createAction(FETCH_AUTOCOMPLETE_ACTIONS.failure);

const fetchAutocomplete: AsyncThunkPayloadCreator<unknown, { query: string; countryCodes: string }, object> = async ({ query, countryCodes }, { dispatch, getState }) => {
	if (query.length < 3) return;
	const csrfToken = (getState() as ApplicationState).surveyState.csrfToken;
	try {
		const response = await appAxios.get("/autocomplete", { headers: { "x-csrf-token": csrfToken, q: query, countryCodes }, withCredentials: true });
		const items = (response.data as AutocompleteResponse).items;
		dispatch(fetchAutocompleteSuccess(items));
	} catch (e) {
		axiosErrorHelper(e);
		dispatch(fetchAutocompleteFailure());
		if (axios.isAxiosError(e) && e.response?.status === 403) {
			dispatch(setFetchErrorMessage(ERROR_MESSAGE_SESSION));
		} else {
			dispatch(setFetchErrorMessage(ERROR_MESSAGE_DEFAULT));
		}
	}
};

export const debouncedFetchAutocomplete = createAsyncThunk<unknown, { query: string; countryCodes: string }, ThunkApi>("geocode/autocomplete", debounce(fetchAutocomplete, API_CALL_INTERVAL_MILLISECOND));

const FETCH_GEOCODE_ACTIONS = {
	pending: `geocode/${FetchStatus.PENDING}`,
	success: `geocode/${FetchStatus.SUCCESS}`,
	failure: `geocode/${FetchStatus.FAILURE}`,
};
const fetchGeocodeSuccess = createAction(FETCH_GEOCODE_ACTIONS.success, (items: HereGeocodeResult[]) => ({
	payload: items,
}));
const fetchGeocodeFailure = createAction(FETCH_GEOCODE_ACTIONS.failure);

const fetchGeocode: AsyncThunkPayloadCreator<unknown, { query: string; countryCodes: string }, object> = async ({ query, countryCodes }, { dispatch, getState }) => {
	if (query.length < 3) return;
	const csrfToken = (getState() as ApplicationState).surveyState.csrfToken;
	try {
		const response = await appAxios.get("/geocode", { headers: { "x-csrf-token": csrfToken, q: query, countryCodes }, withCredentials: true });
		const items = (response.data as GeocodeResponse).items;
		dispatch(fetchGeocodeSuccess(items));
	} catch (e) {
		axiosErrorHelper(e);
		dispatch(fetchGeocodeFailure());
		if (axios.isAxiosError(e) && e.response?.status === 403) {
			dispatch(setFetchErrorMessage(ERROR_MESSAGE_SESSION));
		} else {
			dispatch(setFetchErrorMessage(ERROR_MESSAGE_DEFAULT));
		}
	}
};

export const debouncedFetchGeocode = createAsyncThunk<unknown, { query: string; countryCodes: string }, ThunkApi>("geocode/geocode", debounce(fetchGeocode, API_CALL_INTERVAL_MILLISECOND));

const homeAddressSlice = createSlice({
	name: "homeAddressSlice",
	initialState,
	reducers: {
		clearAddressGeocode: (state) => {
			state.homeAddressGeocode.latitude = null;
			state.homeAddressGeocode.longitude = null;
		},
		setHomeAddressFields: (state, { payload }: PayloadAction<HomeAddressFields>) => {
			state.homeAddressFields = payload;
		},
		setNoStreetAddressChecked: (state, { payload }: PayloadAction<boolean>) => {
			state.noStreetAddressChecked = payload;
		},
		resetSuggestedAddresses: (state) => {
			state.homeAddressFields.suggestedAddresses = [];
		},
		resetAddressFetchStatus: (state) => {
			state.fetchStatus = initialState.fetchStatus;
		},
		setFetchErrorMessage: (state, { payload }: PayloadAction<string>) => {
			state.fetchErrorMessage = payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(FETCH_AUTOCOMPLETE_ACTIONS.pending, (state) => {
				state.fetchStatus = FetchStatus.PENDING;
			})
			.addCase(FETCH_AUTOCOMPLETE_ACTIONS.success, (state, { payload }: PayloadAction<AutocompleteAddress[]>) => {
				if (payload.length > 0) {
					state.fetchStatus = FetchStatus.SUCCESS;
				} else {
					state.fetchStatus = FetchStatus.IDLE;
				}
				state.homeAddressFields.suggestedAddresses = payload;
			})
			.addCase(FETCH_AUTOCOMPLETE_ACTIONS.failure, (state) => {
				state.fetchStatus = FetchStatus.FAILURE;
			})
			.addCase(FETCH_GEOCODE_ACTIONS.success, (state, { payload }: PayloadAction<HereGeocodeResult[]>) => {
				state.fetchStatus = FetchStatus.SUCCESS;
				const geocoded = payload[0];
				const lowCertainty = geocoded.resultType !== AddressResultType.houseNumber && geocoded.resultType !== AddressResultType.street;
				const { lat, lng } = geocoded.position;
				state.homeAddressGeocode = {
					latitude: lat,
					longitude: lng,
				};
				state.lowCertaintyAddress = lowCertainty;
			})
			.addCase(FETCH_GEOCODE_ACTIONS.failure, (state) => {
				state.fetchStatus = FetchStatus.FAILURE;
			})
			.addCase(resetUserData, () => initialState);
	},
});

export const selectHomeAddressFields = (state: ApplicationState): HomeAddressFields => state.homeAddressSliceState.homeAddressFields;
export const selectNoStreetAddressChecked = (state: ApplicationState): boolean => state.homeAddressSliceState.noStreetAddressChecked;
export const selectLowCertaintyAddress = (state: ApplicationState): boolean => state.homeAddressSliceState.lowCertaintyAddress;
export const selectAddressGeocode = (state: ApplicationState): Geocode => state.homeAddressSliceState.homeAddressGeocode;
export const selectAddressFetchStatus = (state: ApplicationState): FetchStatus => state.homeAddressSliceState.fetchStatus;
export const selectAddressFetchErrorMessage = (state: ApplicationState): string => state.homeAddressSliceState.fetchErrorMessage;

export const { clearAddressGeocode, setHomeAddressFields, setNoStreetAddressChecked, resetSuggestedAddresses, resetAddressFetchStatus, setFetchErrorMessage } = homeAddressSlice.actions;

export const reducer = homeAddressSlice.reducer;
