import { Fragment, ReactElement } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import "../scss/App.scss";
import SurveyLoadError from "./SurveyLoadError";

import { logger } from "../lib/logApi";
import { useAppSelector } from "../store/hooks";
import { selectErrorFetchingSurveyOnlineStatus } from "../store/surveySlice";

const log = logger();

const App = (): ReactElement => {
	log.info(`${import.meta.env.VITE_TITLE as string} ${import.meta.env.VITE_VERSION as string}`);
	const errorFetchingSurveyOnlineStatus = useAppSelector(selectErrorFetchingSurveyOnlineStatus);

	return (
		<Fragment>
			<Header />
			<main className="flex-fill bg-secondary text-light d-flex flex-column">
				{errorFetchingSurveyOnlineStatus && <SurveyLoadError />}
				{!errorFetchingSurveyOnlineStatus && <Outlet />}
			</main>
		</Fragment>
	);
};

export default App;
