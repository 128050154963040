import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import isEqual from "lodash/isEqual";

import { ApplicationState } from ".";
import { WeekDay, WORKPLACE_VALUE_WORK_FROM_HOME } from "../constants/surveyConstants";
import { resetUserData } from "./surveySlice";

export enum CommuteProperties {
	/**
	 * The day of the week
	 */
	Day = "Day",
	NonWorkingDay = "NonWorkingDay",
	/**
	 * The direction of a commute: travelling to or from work
	 */
	IsToWork = "IsToWork",
	WorkplaceID = "WorkplaceID",
	/**
	 * Primary vehicle used for commute
	 */
	PrimaryModeID = "PrimaryModeID",
	/**
	 * Secondary vehicle used for commute
	 */
	SecondaryModeID = "SecondaryModeID",
	/**
	 * Fuel type of the primary vehicle
	 */
	PrimaryModeVehicleTypeID = "PrimaryModeVehicleTypeID",
	/**
	 * Fuel type of the secondary vehicle
	 */
	SecondaryModeVehicleTypeID = "SecondaryModeVehicleTypeID",
	/**
	 * The visibility of the secondary travel mode
	 */
	showSecondMode = "showSecondMode",
}

export interface IValidateString {
	value: string;
	valid: boolean;
}

export interface ITrip {
	// [CommuteProperties.day]: number | null;
	[CommuteProperties.WorkplaceID]: IValidateString;
	[CommuteProperties.IsToWork]: boolean | null;
	[CommuteProperties.NonWorkingDay]: boolean;
	[CommuteProperties.PrimaryModeID]: IValidateString;
	[CommuteProperties.PrimaryModeVehicleTypeID]: IValidateString;
	[CommuteProperties.SecondaryModeID]: IValidateString;
	[CommuteProperties.SecondaryModeVehicleTypeID]: IValidateString;
	[CommuteProperties.showSecondMode]: boolean;
}

export type ICommuteStateByDay = ITrip[];

export type ICommuteState = Record<WeekDay, ICommuteStateByDay>;

export interface ITripForSubmit {
	Day?: number;
	NonWorkingDay?: boolean;
	IsToWork?: boolean;
	WorkplaceID?: string;
	PrimaryModeID?: string;
	SecondaryModeID?: string;
	PrimaryModeVehicleTypeID?: string;
	SecondaryModeVehicleTypeID?: string;
}

export const EMPTY_VALIDATE_STRING = {
	value: "",
	valid: false,
};

const EMPTY_MODES_AND_FUEL = {
	[CommuteProperties.PrimaryModeID]: EMPTY_VALIDATE_STRING,
	[CommuteProperties.PrimaryModeVehicleTypeID]: EMPTY_VALIDATE_STRING,
	[CommuteProperties.SecondaryModeID]: EMPTY_VALIDATE_STRING,
	[CommuteProperties.SecondaryModeVehicleTypeID]: EMPTY_VALIDATE_STRING,
};

const initialStateSingleTrip: ITrip = {
	// "day" is left out so it doesn't get copied to the next day
	[CommuteProperties.WorkplaceID]: EMPTY_VALIDATE_STRING,
	[CommuteProperties.IsToWork]: null,
	[CommuteProperties.NonWorkingDay]: false,
	...EMPTY_MODES_AND_FUEL,
	[CommuteProperties.showSecondMode]: false,
};

export const initialStateTripToWork: ITrip = {
	...initialStateSingleTrip,
	[CommuteProperties.IsToWork]: true,
};

export const initialStateTripFromWork: ITrip = {
	...initialStateSingleTrip,
	[CommuteProperties.IsToWork]: false,
};

const initialStateByDay: ICommuteStateByDay = [initialStateTripToWork, initialStateTripFromWork];

const WeekDayIds = {
	[WeekDay.MON]: { id: 1, day: WeekDay.MON },
	[WeekDay.TUE]: { id: 2, day: WeekDay.TUE },
	[WeekDay.WED]: { id: 3, day: WeekDay.WED },
	[WeekDay.THU]: { id: 4, day: WeekDay.THU },
	[WeekDay.FRI]: { id: 5, day: WeekDay.FRI },
	[WeekDay.SAT]: { id: 6, day: WeekDay.SAT },
	[WeekDay.SUN]: { id: 7, day: WeekDay.SUN },
};

const initialState: ICommuteState = {
	[WeekDay.MON]: initialStateByDay,
	[WeekDay.TUE]: initialStateByDay,
	[WeekDay.WED]: initialStateByDay,
	[WeekDay.THU]: initialStateByDay,
	[WeekDay.FRI]: initialStateByDay,
	[WeekDay.SAT]: initialStateByDay,
	[WeekDay.SUN]: initialStateByDay,
};

export const getNextDay = (day: WeekDay): WeekDay | undefined => {
	if (!(day in WeekDayIds)) throw new Error("No such day of WeekDay");
	const id: number = WeekDayIds[day].id;
	if (id >= WeekDayIds[WeekDay.SUN].id) {
		return;
	}
	const nextDayId = id + 1;
	return Object.values(WeekDayIds).find((item) => item.id === nextDayId)?.day;
};

const setTripSelectionsValid = (trip: ITrip) => {
	// not working or workFromHome: set all trip selections valid
	trip.PrimaryModeID.valid = true;
	trip.PrimaryModeVehicleTypeID.valid = true;
	trip.SecondaryModeID.valid = true;
	trip.SecondaryModeVehicleTypeID.valid = true;
};

const conditionalSubtripValidation = (trip: ITrip, carTravelModesAsIDs: string[]) => {
	if (trip.PrimaryModeID.valid) {
		// not vehicle
		if (!carTravelModesAsIDs.includes(trip.PrimaryModeID.value) && trip.PrimaryModeVehicleTypeID) {
			trip.PrimaryModeVehicleTypeID.valid = true;
		}
		// no second mode
		if (!trip.showSecondMode && trip.SecondaryModeID) {
			trip.SecondaryModeID.valid = true;
			if (trip.SecondaryModeVehicleTypeID) {
				trip.SecondaryModeVehicleTypeID.valid = true;
			}
		} else if (trip.SecondaryModeID) {
			// have second mode, but not vehicle
			if (!carTravelModesAsIDs.includes(trip.SecondaryModeID.value) && trip.SecondaryModeVehicleTypeID) {
				trip.SecondaryModeVehicleTypeID.valid = true;
			}
		}
	}
};

const commuteSlice = createSlice({
	name: "commuteSlice",
	initialState,
	reducers: {
		updateCommuteByDay: (state, action: PayloadAction<{ key: WeekDay; value: ICommuteStateByDay }>) => {
			const { key, value } = action.payload;
			state[key] = value;
		},
		updateCommutePropertyByDayTrip: (state, action: PayloadAction<{ key: WeekDay; value: IValidateString | boolean; propertyToUpdate: CommuteProperties; isTripToWork: boolean }>) => {
			const { key, value, propertyToUpdate, isTripToWork } = action.payload;
			state[key] = state[key].map((trip) =>
				trip.IsToWork === isTripToWork
					? {
							...trip,
							[propertyToUpdate]: value,
						}
					: trip
			);
		},
		updateCommutePropertyByDayTrips: (state, action: PayloadAction<{ key: WeekDay; value: IValidateString | boolean; propertyToUpdate: CommuteProperties }>) => {
			const { key, value, propertyToUpdate } = action.payload;
			state[key] = state[key].map((trip) => ({
				...trip,
				[propertyToUpdate]: value,
			}));
		},
		resetCommutePropertiesByDayTrip: (state, action: PayloadAction<{ key: WeekDay; properties: CommuteProperties[]; isTripToWork: boolean }>) => {
			const { key, properties, isTripToWork } = action.payload;
			let draft = {};
			properties.forEach((property) => {
				draft = {
					...draft,
					[property]: EMPTY_VALIDATE_STRING,
				};
			});
			state[key] = state[key].map((trip) =>
				trip.IsToWork === isTripToWork
					? {
							...trip,
							...draft,
						}
					: trip
			);
		},
		resetAllPropertiesForNonWorkingDay: (state, action: PayloadAction<{ key: WeekDay }>) => {
			const { key } = action.payload;
			state[key] = state[key].map((trip) => ({
				...trip,
				[CommuteProperties.WorkplaceID]: EMPTY_VALIDATE_STRING,
				...EMPTY_MODES_AND_FUEL,
			}));
		},
		resetAllPropertiesForWorkFromHomeDay: (state, action: PayloadAction<{ key: WeekDay }>) => {
			const { key } = action.payload;
			state[key] = state[key].map((trip) => ({
				...trip,
				...EMPTY_MODES_AND_FUEL,
			}));
		},
		validateSelections: (state, action: PayloadAction<string[]>) => {
			/** Iterates through what has been selected
			 * initialState has valid=false for ALL inputs, valid gets set true when a value is selected
			 * This reducer marks children as valid if they are *not required* to have a value
			 */
			const carTravelModesAsIDs = action.payload;
			const commuteState = { ...state };
			Object.values(commuteState).forEach((trips) => {
				trips.forEach((trip) => {
					if (trip.NonWorkingDay) {
						// is NOT a working day - nothing below this (both directions) is required
						trip.WorkplaceID.valid = true;
						setTripSelectionsValid(trip);
					} else if (trip.WorkplaceID.value === WORKPLACE_VALUE_WORK_FROM_HOME.value) {
						// working from home: nothing else required
						setTripSelectionsValid(trip);
					} else {
						// travel to/from work
						conditionalSubtripValidation(trip, carTravelModesAsIDs);
					}
				});
			});
			state = { ...commuteState };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(resetUserData, () => initialState);
	},
});

export const selectCommuteState = (state: ApplicationState): ICommuteState => state.commuteState;

/**
 * Get an array of trips representing the entire commute state for submission.
To cater for the submission, each element (trip)
- has an extra "Day" property added to indicate the day of the week,
- has all the unrequested state removed
- has all empty string values replaced with null
 */
export const selectCommuteStateInArray = createSelector(selectCommuteState, (state: ICommuteState): ITripForSubmit[] => {
	let result: ITripForSubmit[] = [];
	Object.entries(state).forEach(([day, trips]) => {
		const tripsWithDayId = trips.map((trip) => {
			let tripForSubmit: ITripForSubmit = {};
			Object.entries(trip).forEach(([key, value]) => {
				// Leave out any UI-related state
				if (key === CommuteProperties.showSecondMode) return;

				const typedValue = value as IValidateString | number | boolean | undefined | null;

				// Change values for submission
				let reTypedValue = typedValue as string | null;
				// EMPTY_VALIDATE_STRING or undefined to null
				if (isEqual(typedValue, EMPTY_VALIDATE_STRING) || typedValue === undefined) {
					reTypedValue = null;
				}
				// if it is a ValidateString (we can't directly test that type for some reason)
				if (!(typeof typedValue === "boolean") && !(typeof typedValue === "number") && !(typeof typedValue === "undefined") && !(typedValue === null)) {
					if (typedValue.value === WORKPLACE_VALUE_WORK_FROM_HOME.value) {
						// WFH = null
						reTypedValue = null;
					} else if (typedValue.value.length > 0) {
						// if value exists: extract value for submission
						reTypedValue = typedValue.value;
					} else {
						// else null
						reTypedValue = null;
					}
				}

				tripForSubmit = {
					...tripForSubmit,
					[key]: reTypedValue,
					// Add the "Day" property for submission
					[CommuteProperties.Day]: WeekDayIds[day as WeekDay].id,
				};
			});
			return tripForSubmit;
		});
		result = [...result, ...tripsWithDayId];
	});
	return result;
});

export const selectCommuteFirstInvalidDay = (state: ApplicationState): number | undefined => {
	let firstInvalidDay: number | undefined;
	Object.values(state.commuteState).forEach((trips, index) => {
		trips.forEach((trip) => {
			Object.values(trip).forEach((value) => {
				if (!(typeof value === "boolean") && !(value === null)) {
					if ("value" in (value as IValidateString)) {
						if (!(value as IValidateString).valid) {
							if (firstInvalidDay === undefined) {
								firstInvalidDay = index;
							}
						}
					}
				}
			});
		});
	});
	return firstInvalidDay;
};

export const { updateCommuteByDay, updateCommutePropertyByDayTrip, updateCommutePropertyByDayTrips, resetCommutePropertiesByDayTrip, resetAllPropertiesForNonWorkingDay, resetAllPropertiesForWorkFromHomeDay, validateSelections } =
	commuteSlice.actions;

export const reducer = commuteSlice.reducer;
