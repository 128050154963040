import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApplicationState } from ".";

/**
 * @param total the total number of pages
 * @param current current page index counting from 1
 */
export interface IPageState {
	total: number;
	current: number;
	goNextEnabled: boolean;
}

export const UNSET_PAGE_INDEX = 0;

const initialState: IPageState = {
	total: 0,
	current: UNSET_PAGE_INDEX,
	goNextEnabled: true,
};

const pageSlice = createSlice({
	name: "pageSlice",
	initialState,
	reducers: {
		updatePageIndex: (state, action: PayloadAction<number>) => {
			state.current = action.payload;
		},
		updateTotalPage: (state, action: PayloadAction<number>) => {
			state.total = action.payload;
		},
		updateGoNextEnabled: (state, action: PayloadAction<boolean>) => {
			state.goNextEnabled = action.payload;
		},
	},
});

export const selectTotalPage = (state: ApplicationState): number => state.pageState.total;
export const selectCurrentPage = (state: ApplicationState): number => state.pageState.current;
export const selectGoNextEnabled = (state: ApplicationState): boolean => state.pageState.goNextEnabled;

export const { updatePageIndex, updateTotalPage, updateGoNextEnabled } = pageSlice.actions;
export const reducer = pageSlice.reducer;
