import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { createReduxHistoryContext } from "redux-first-history";

// imported in order of use in the application
import { ICommuteState, reducer as commuteStateReducer } from "./commuteSlice";
import { EmailSliceState, reducer as emailSliceReducer } from "./emailSlice";
import { HomeAddressSliceState, reducer as homeAddressSliceReducer } from "./homeAddressSlice";
import { NavState, reducer as navReducer } from "./navSlice";
import { IOptionalQuestionState, reducer as optionalQuestionReducer } from "./optionalQuestionSlice";
import { IPageState, reducer as pageReducer } from "./pageSlice";
import { ISurveyState, reducer as surveyReducer } from "./surveySlice";
import { IState as MainWorkplaceState, reducer as mainWorkplaceReducer } from "./mainWorkplaceSlice";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
	history: createBrowserHistory(),
});

const rootReducer = combineReducers({
	navState: navReducer,
	pageState: pageReducer,
	commuteState: commuteStateReducer,
	emailSliceState: emailSliceReducer,
	mainWorkplaceState: mainWorkplaceReducer,
	homeAddressSliceState: homeAddressSliceReducer,
	surveyState: surveyReducer,
	optionalQuestionState: optionalQuestionReducer,
	router: routerReducer,
});

const store = configureStore({
	reducer: rootReducer,
	preloadedState: undefined,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: true,
			immutableCheck: true,
			serializableCheck: true,
		}).concat(routerMiddleware),
	devTools: import.meta.env.MODE === "development",
});
createReduxHistory(store);

// The top-level state object
export interface ApplicationState {
	navState: NavState;
	pageState: IPageState;
	commuteState: ICommuteState;
	emailSliceState: EmailSliceState;
	mainWorkplaceState: MainWorkplaceState;
	homeAddressSliceState: HomeAddressSliceState;
	surveyState: ISurveyState;
	optionalQuestionState: IOptionalQuestionState;
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, ApplicationState, unknown, Action>;
export interface AppThunkAction<TAction> {
	(dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export interface BaseState {
	isBusy: boolean; // use this state to denote the backend is busy with an async process
	errorMessage?: string;
}

export type ThunkApi = {
	state: ApplicationState;
};

export function setupStore(preloadedState?: Partial<RootState>) {
	return configureStore({
		reducer: rootReducer,
		preloadedState
	})
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>

export default store;
