import { ReactElement } from "react";
import PageHeadings from "../components/PageHeadings";
import SurveyPageContainer from "../components/SurveyPageContainer";
import EmptySpaceLgScreen from "./cards/EmptySpaceLgScreen";

const SurveyLoadError = (): ReactElement => (
	<SurveyPageContainer rootClass="main-background-img">
		<EmptySpaceLgScreen />
		<PageHeadings title="Oops something went wrong." description="There was an error launching the survey, please try again later." />
	</SurveyPageContainer>
);

export default SurveyLoadError;
