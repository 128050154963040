import clsx from "clsx";
import { ReactElement, useRef, useState } from "react";
import { Placement } from "react-bootstrap/esm/types";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { isBrowser } from "react-device-detect";
import QuestionIcon from "../assets/tooltip.svg?react";

interface TooltipProps {
	content: string;
}

const GenericTooltip = ({ content }: TooltipProps): ReactElement => {
	const [isShow, setShow] = useState(false);
	const target = useRef(null);

	const show = () => {
		setShow(true);
	};

	const hide = () => {
		setShow(false);
	};

	const fillClassName = clsx("fill-transition", { "icon-fill-active": isShow, "icon-fill-default": !isShow });
	const placement: Placement = isBrowser ? "right" : "bottom-end";

	return (
		<>
			<div data-testid="tooltip-icon" className="d-inline-flex justify-content-center align-items-center p-1 button-pointer" ref={target} onClick={show}>
				<QuestionIcon width={21} height={21} className={fillClassName} />
			</div>
			<Overlay target={target.current} show={isShow} placement={placement} rootClose={true} rootCloseEvent="click" onHide={hide}>
				{(props) => (
					<Tooltip {...props} className="lh-sm" id="tooltip">
						{content}
					</Tooltip>
				)}
			</Overlay>
		</>
	);
};

export default GenericTooltip;
