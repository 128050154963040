import { Navigate, Outlet } from "react-router-dom";

import { NAV_LINKS } from "./constants/appConstants";
import App from "./layout/App";

export const routesConfig = [
	{
		path: "/",
		element: <App />,
		children: [
			{ index: true, lazy: () => import("./layout/CompanyPage") },
			{ path: NAV_LINKS.NoOpenSurveysPage, lazy: () => import("./layout/surveyPages/NoOpenSurveysPage") },
			{ path: NAV_LINKS.Home, lazy: () => import("./layout/Home") },
			{ path: NAV_LINKS.QuestionEmail, lazy: () => import("./layout/surveyPages/EmailPage") },
			{ path: NAV_LINKS.QuestionMainWorkplace, lazy: () => import("./layout/surveyPages/MainWorkplacePage") },
			{ path: NAV_LINKS.QuestionAddress, lazy: () => import("./layout/surveyPages/AddressPage") },
			{ path: NAV_LINKS.QuestionCommute, lazy: () => import("./layout/surveyPages/CommutePage") },
			{
				path: NAV_LINKS.QuestionOptional,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <Navigate to="0" replace />,
					},
					{
						path: `${NAV_LINKS.QuestionOptional}/:questionPathIdentifier`,
						lazy: () => import("./components/optionalQuestion/OptionalQuestion")
					},
				]
			},
			{ path: NAV_LINKS.ThankYou, lazy: () => import("./layout/surveyPages/ThanksPage") },
			{ path: NAV_LINKS.Exit, lazy: () => import("./layout/surveyPages/ExitInfoPage") },
			{ path: NAV_LINKS.NotFound, lazy: () => import("./layout/NotFound") },
		],
	},
];